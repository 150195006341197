// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classnames from 'classnames'

import map from 'lodash/map'
import filter from 'lodash/filter'
import isInteger from 'lodash/isInteger'
import isNull from 'lodash/isNull'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Iframe from 'react-iframe'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

// import CoverPage from '../components/cover-page'
import '../components/cover-page/style.less'

import BookSchema from '../components/schema/book-schema'
import ProductSchema from '../components/schema/product-schema'

import Link from '../components/link'
import '../components/link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import about from '../seo/about.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

const pageSchema = {
  title: 'Homepage',
  slug: '',
  abstract: about.text,
  breadcrumbs: [{ title: 'Cover Page', slug: '' }],
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img: file(
      relativePath: { eq: "mock/julian-bauer-the-falls-of-nimrodel.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    allResources(sort: { order: ASC, fields: position }) {
      edges {
        node {
          title {
            lang
            content
          }
          position
          routeSlug
          buzzsproutTag
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
/** Page */
const Page = (props) => {
  const {
    data: {
      allResources: { edges },
    },
    pageContext: {
      intl: { language },
    },
    location: { hash },
  } = props

  const nodes = map(edges, 'node').slice(0, -1)
  const intlNodes = map(nodes, (node) => ({
    ...node,
    intlTitle: filter(node.title, ['lang', language])[0].content,
  }))

  console.log(hash)

  return (
    <StandardPageWrapper
      className="home-page"
      pageSchema={pageSchema}
      {...props}
    >
      <BookSchema
        data={{
          name: about.title,
          description: about.text,
        }}
      />
      <ProductSchema
        data={{
          name: about.title,
          description: about.text,
        }}
      />
      <div className="title-and-cover flow">
        <div className="title">
          <h1>Savitri's Splendour</h1>
          <h2>An audiobook</h2>
        </div>
        <GatsbyImage
          image={getImage(props.data.img)}
          objectPosition="center center"
          className="cover"
        />
      </div>
      <p className="hero">
        Savitri: A Legend and a Symbol is an epic poem in blank verse by Sri
        Aurobindo, based upon the theology from the Mahabharata. Its central
        theme revolves around the transcendence of man as the consummation of
        terrestrial evolution and the emergence of an immortal supramental
        gnostic race upon earth. Savitri approaches 24,000 lines and was
        completed by Sri Aurobindo.
      </p>
      {map(intlNodes, (node) => {
        const { intlTitle, position, buzzsproutTag } = node

        let returnThis = <Fragment />

        if (isNull(buzzsproutTag) === false) {
          if (buzzsproutTag !== 'NA') {
            let title = <Fragment>{intlTitle}</Fragment>

            if (isInteger(position) === false) {
              title = (
                <div>
                  <div />
                  <span>{intlTitle}</span>
                </div>
              )
            }

            returnThis = (
              <div
                className={classnames('audio-archive', {
                  parent: isInteger(position) === true,
                  child: isInteger(position) === false,
                })}
              >
                <Link to={hash === `#${position}` ? `/` : `/#${position}`}>
                  {title}
                </Link>
                {hash === `#${position}` && (
                  <div
                    style={{
                      paddingLeft: isInteger(position) === false ? 36 : '0rem',
                    }}
                  >
                    <Iframe
                      url={`https://www.buzzsprout.com/1897213?artist=&client_source=large_player&iframe=true&referrer=https%3A%2F%2Fwww.buzzsprout.com%2F1897213%2Fpodcast%2Fembed&tags=${buzzsproutTag
                        .split(' ')
                        .join('+')}`}
                      width="100%"
                      height="370px"
                      display="initial"
                      position="relative"
                    />
                  </div>
                )}
              </div>
            )
          }
        }

        return returnThis
      })}
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
